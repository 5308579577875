import { store, Model } from "hybrids";

import { enUS } from "date-fns/locale";
import { format, utcToZonedTime } from "date-fns-tz";

import { get } from "~/utils/api";
import Course from "./Course";

interface Event {
  id: string;
  startsAt: string;
  endsAt: string;
  timezone: string;
  course: Course;

  // computed
  timeLabel: string;
  startsAtDate: Date;
  endsAtDate: Date;
}

const Event: Model<Event> = {
  id: true,
  startsAt: "",
  endsAt: "",
  timezone: "",
  course: store.ref(() => Course),

  // computed
  timeLabel: ({ startsAt, endsAt, timezone }) => {
    const startDate = utcToZonedTime(startsAt, timezone);
    const endDate = utcToZonedTime(endsAt, timezone);

    const start = format(startDate, "MMMM do, p", {
      timeZone: timezone,
      locale: enUS,
    });

    let endDateFormat = "p (zzz)";

    if(startDate.getMonth() != endDate.getMonth()) {
      endDateFormat = "MMMM do, " + endDateFormat;
    } else if(startDate.getDay() != endDate.getDay()) {
      endDateFormat = "do, " + endDateFormat;
    }

    const end = format(endDate, endDateFormat, {
      timeZone: timezone,
      locale: enUS,
    });

    return `${start} - ${end}`;
  },

  startsAtDate: ({ startsAt, timezone }) =>
    utcToZonedTime(new Date(startsAt), timezone),

  endsAtDate: ({ endsAt, timezone }) =>
    utcToZonedTime(new Date(endsAt), timezone),

  [store.connect]: {
    get: (id) => get("/events/:id", id),
  },
};

export default Event;
