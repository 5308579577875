import { format } from "date-fns";
import { store, Model } from "hybrids";
import { get, post } from "~/utils/api";
import Event from "./Event";
import Sponsor from "./Sponsor";
import User from "./User";

interface Course {
  id: string;
  name: string;
  owner: User;
  sponsors: Sponsor[];
  collaborators: User[];
  startsAt: string;
  endsAt: string;
  nextEvent: Event;
  timezone: string;
  thumbnail: string;
  poster: string;
  session: boolean;
  private: boolean;
  likeCount: number;
  url: string;
  shareUrl: string;
  currency: string;
  onlinePromoTicketsAvailable: boolean;
  inPersonPromoTicketsAvailable: boolean;
  donationTitle: string;
  donationDescription: string;
  donationSuggestedAmount: number;
}

export const PER_PAGE = 30;

const Course: Model<Course> = {
  id: true,
  name: "",
  owner: User,
  sponsors: [Sponsor],
  collaborators: [User],
  startsAt: "",
  endsAt: "",
  nextEvent: Event,
  timezone: "",
  thumbnail: "",
  poster: "",
  session: false,
  private: false,
  likeCount: 0,
  url: "",
  shareUrl: "",
  currency: "USD",
  onlinePromoTicketsAvailable: false,
  inPersonPromoTicketsAvailable: false,
  donationTitle: "",
  donationDescription: "",
  donationSuggestedAmount: 0.0,
  [store.connect]: {
    get: (id) => get("/courses/:id", id),
    set(id, values, keys) {
      if (id && values && keys.length == 1 && keys[0] == "likeCount") {
        const { likeCount } = store.get(Course, id);
        return post(
          `/courses/:id/${values.likeCount > likeCount ? "like" : "dislike"}`,
          null,
          id
        );
      }
      throw TypeError("Set action with provided values not supported");
    },
    list(query) {
      if (query && typeof query === "object" && query.tag) {
        return get("/courses/tagged", {
          tag: query.tag,
          page: query.page,
          past: query.past,
        });
      }

      return get("/courses", query);
    },
    offline: 1000 * 60 * 60 * 24 * 7, // 7 days
  },
};

export default Course;
